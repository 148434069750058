const themeGen = theme => ({ // eslint-disable-line
  noBorder: true,
  fg: theme.palette.inkmannBlack,
  bg: 'white',
  showLogoutIcon: false,
  combineSections: true,
  bottomBorderColor: '#343434',
});

export default themeGen;
