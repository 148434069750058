// eslint-disable-next-line no-unused-vars
const themeGen = (theme) => {
  const res = {
    conf: {
      showSideNavigationOnStaticPages: true,
      showTitleOnContentPageDesktop: true,
      showTitleOnContentPageMobile: true,
      showHomepageCategorySection: false,
    },
    sideContentWidth: '22%',
    sideContentPadding: 'px-2',
  };
  return res;
};

export default themeGen;
